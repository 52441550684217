<script setup>
import { defineProps, defineEmits, computed, watch } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits(['add'])
const store = useStore()

const props = defineProps({
  variables: {
    type: Array,
    required: true
  },
  isOpen: {
    type: Boolean,
    required: true
  },
  variablesKey: {
    type: String,
    required: true
  }
})

const getIsEdit = computed(() => {
  return store.getters['parameter/getIsEdit']
})

watch(props.variables, () => {
  if (getIsEdit.value) {
    addItem(props.variables[0])
  }
})

function addItem (item) {
  emit('add', item)
}
</script>

<template>
  <ul class="search-variables-list no-close" v-if="isOpen && variables.length">
    <li
      v-for="item in variables.slice(0, 4)"
      :key="item.id"
      class="search-variables-list__item"
      @click="addItem(item)"
    >
      {{ item[variablesKey] }}
    </li>
  </ul>

  <ul class="search-variables-list" v-if="isOpen && !variables.length">
    <li class="search-variables-list__item">Ничего не найдено</li>
  </ul>
</template>
