<template>
  <div :class="['form__control search-variables mb-20', classForm, classSearch ? 'search' : '']">
    <input
      :class="['input', classInput, errorLength]"
      :id="id"
      :name="name"
      type="text"
      autocomplete="off"

      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"

      @input="handlerValueInput"
      @blur="setBlur"
    >

    <SearchVariables
      v-if="isVariables"
      :variables="variables"
      :isOpen="isOpen"
      :variablesKey="variablesKey"
      @add="addItem"
    />
<!--    <label v-if="classInput !== 'input-border-all'" :for="id" :class="['label-valid', errorLength ? 'error' : 'success']"></label>-->
    <VIcon
      :class="['input__eye input__eye-hidden', errorLength]"
      width="23"
      height="23"
      icon-name="Search"
      @click="onInput"
    />

    <div v-if="errors?.length">
      <small class="error-small">{{ errors[0].$message }}</small>
    </div>
    <small v-if="description && !errors?.length" class="description">{{ description }}</small>
  </div>
</template>

<script>
import { ref, computed, onUnmounted, reactive, watch } from 'vue'
import { fieldProps } from '@/mixin/props'
import VIcon from '@/components/ui/icon/VIcon'
import { DELAY_SEARCH } from '@/utils/consts'
import SearchVariables from '@/components/ui/form/Search/SearchVariables'

export default {
  emits: ['add', 'selected'],
  components: {
    SearchVariables,
    VIcon
  },
  props: {
    modelValue: {
      type: String
    },
    isVariables: {
      type: Boolean,
      required: false
    },
    variables: {
      type: Array,
      required: false
    },
    variablesKey: {
      type: String,
      required: false,
      default: 'id'
    },
    classSearch: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mixins: [fieldProps],
  setup (props, { emit }) {
    const isLoading = ref(false)
    const search = reactive([])
    const errorLength = computed(() => {
      return props.errors?.length ? 'error' : ''
    })
    const isOpen = ref(false)

    const setBlur = () => {
      emit('blur', props.name)
    }

    const getModelValue = computed(() => {
      return props.modelValue
    })

    watch(getModelValue, () => {
      if (!props.modelValue) {
        isOpen.value = false
      }
    })

    const handlerValueInput = (e) => {
      emit('selected', false)
      if (isLoading.value) {
        return
      }
      isLoading.value = true
      search.push(e.target.value)
      setTimeout(() => {
        isLoading.value = false
        emit('update:modelValue', e.target.value)
        emit('input', props.name)
        if (props.isVariables) {
          isOpen.value = true
        }
      }, DELAY_SEARCH)
    }

    const onInput = () => {
      emit('input')
    }

    const addItem = (val) => {
      isOpen.value = false
      emit('selected', true)
      emit('update:modelValue', val[props.variablesKey])
      emit('add', val)
    }

    function closeVariables () {
      isOpen.value = false
    }

    window.addEventListener('click', closeVariables)

    onUnmounted(() => {
      window.removeEventListener('click', closeVariables)
    })

    return {
      addItem,
      onInput,
      isLoading,
      errorLength,
      setBlur,
      isOpen,
      handlerValueInput
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  min-width: 670px;
  max-width: initial;
}
</style>
