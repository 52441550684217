export const adminUsers = [
  {
    name: 'Все пользователи',
    routeName: 'admin-users-all'
  },
  {
    name: 'Участники ТП',
    routeName: 'admin-users-members-tp'
  },
  {
    name: 'Организаторы',
    routeName: 'admin-users-organizer'
  },
  {
    name: 'Администраторы',
    routeName: 'admin-users-admins'
  },
  {
    name: 'Служба безопасности',
    routeName: 'admin-users-security'
  },
  {
    name: 'Журнал',
    routeName: 'admin-users-journal'
  },
  {
    name: 'Заблокированные',
    routeName: 'admin-users-banned'
  }
]
export const adminDiagnostic = [
  {
    name: 'О сбоях и ошибках',
    routeName: 'admin-diagnostic-error'
  },
  // {
  //   name: 'Статистика работы СЭТ',
  //   routeName: 'admin-diagnostic-set'
  // }
]
export const adminTrade = [
  {
    name: 'Активные',
    routeName: 'admin-trade-active'
  },
  {
    name: 'Архивные',
    routeName: 'admin-trade-archive'
  },
  {
    name: 'Удалённые',
    routeName: 'admin-trade-delete'
  }
]

export const securityTrade = [
  {
    name: 'Активные',
    routeName: 'security-trade-active'
  },
  {
    name: 'Архивные',
    routeName: 'security-trade-archive'
  }
]

export const userTrade = [
  {
    name: 'Активные',
    routeName: 'bidder-trade-active'
  },
  {
    name: 'Архивные',
    routeName: 'bidder-trade-archive'
  }
]

export const securityUsers = [
  {
    name: 'Все пользователи',
    routeName: 'security-users-all'
  },
  {
    name: 'Участники ТП',
    routeName: 'security-users-members-tp'
  },
  {
    name: 'Заблокированные',
    routeName: 'security-users-banned'
  }
]
export const securityAccreditation = [
  {
    name: 'Активные',
    routeName: 'security-accreditation-active'
  },
  {
    name: 'Архивные',
    routeName: 'security-accreditation-archive'
  }
]

export const bidderMyAuctions = [
  {
    name: 'Активные ТП',
    routeName: 'bidder-my-auctions-active'
  },
  {
    name: 'Архивные ТП',
    routeName: 'bidder-my-auctions-archive'
  },
  {
    name: 'Протоколы',
    routeName: 'bidder-my-auctions-protocols'
  }
]

export const bidderTrade = [
  {
    name: 'Активные ТП',
    routeName: 'bidder-trade-active'
  },
  {
    name: 'Архивные ТП',
    routeName: 'bidder-trade-archive'
  },
  {
    name: 'Мои заявки',
    routeName: 'bidder-trade-my-applications'
  }
]
export const bidderProfile = [
  {
    name: 'Основная информация',
    routeName: 'bidder-profile-info'
  },
  {
    name: 'Документы',
    routeName: 'bidder-profile-documents'
  }
]

export const organizerTrade = [
  {
    name: 'Активные ТП',
    routeName: 'organizer-my-trade-active'
  },
  {
    name: 'Архивные ТП',
    routeName: 'organizer-my-trade-archive'
  }
]

export const organizerMyTrade = [
  {
    name: 'Активные ТП',
    routeName: 'organizer-my-trade-active'
  },
  {
    name: 'Архивные ТП',
    routeName: 'organizer-my-trade-archive'
  },
  {
    name: 'Подведение итогов',
    routeName: 'organizer-my-trade-results'
  },
  {
    name: 'Статистика работы',
    routeName: 'organizer-my-trade-statistics'
  }
]

export const organizerProfile = [
  {
    name: 'Основная информация',
    routeName: 'organizer-profile-info'
  },
  {
    name: 'Документы',
    routeName: 'organizer-profile-documents'
  }
  // {
  //   name: 'Журнал событий',
  //   routeName: 'organizer-profile-changelog'
  // }
]

export const biddingStatus = [
  {
    name: 'История предложений',
    routeName: 'bidding-procedure-history'
  },
  {
    name: 'Статистика предложений',
    routeName: 'bidding-procedure-statistic'
  }
]

export const notification = [
  {
    name: 'Все уведомления',
    routeName: 'notification-all'
  }
  // {
  //   name: 'Новые',
  //   routeName: 'notification-new'
  // }
]

export const adminProfile = [
  {
    name: 'Основная информация',
    routeName: 'admin-profile-info'
  },
  {
    name: 'Документы',
    routeName: 'admin-profile-documents'
  },
  {
    name: 'Журнал событий',
    routeName: 'admin-profile-changelog'
  }
]

export const securityProfile = [
  {
    name: 'Основная информация',
    routeName: 'security-profile-info'
  },
  {
    name: 'Документы',
    routeName: 'security-profile-documents'
  },
  {
    name: 'Журнал событий',
    routeName: 'security-profile-changelog'
  }
]
