import { computed, onMounted, reactive } from 'vue'
import { userFilterApi } from '@/http/user/userFilterApi'
import { refreshArr } from '@/utils/reactive'
import { Alert } from '@/extension/Alert/Alert'
import { useRoute } from 'vue-router'

export function useTradeFilterUsers(
  {
    hasArchive,
    hasDeleted
  } = {
      hasArchive: false,
      hasDeleted: false
    },
  params = {}
) {
  const { filterFormData, resetFilter } = useFilterFormData()

  console.log(params)
  const companies = reactive({
    active: [],
    archive: [],
    deleted: []
  })
  const organizers = reactive({
    active: [],
    archive: [],
    deleted: []
  })

  onMounted(fetchOptions)

  async function fetchOptions() {
    try {
      const response = await Promise.all([
        userFilterApi.getTradeOfferCompaniesAndOrganizers({
          'search[Active]': 'active',
          ...params
        }),
        hasArchive && userFilterApi.getTradeOfferCompaniesAndOrganizers({
          'search[Active]': 'archive',
          ...params
        }),
        hasDeleted && userFilterApi.getTradeOfferCompaniesAndOrganizers({
          'search[Deleted]': '',
          ...params
        })
      ])

      refreshArr(companies.active, response[0]?.companies.map(mapCompanies))
      refreshArr(organizers.active, response[0]?.responsible.map(mapOrganizers))

      if (hasArchive) {
        refreshArr(companies.archive, response[1]?.companies.map(mapCompanies))
        refreshArr(organizers.archive, response[1]?.responsible.map(mapOrganizers))
      }

      if (hasDeleted) {
        refreshArr(companies.deleted, response[2]?.companies.map(mapCompanies))
        refreshArr(organizers.deleted, response[2]?.responsible.map(mapOrganizers))
      }
    } catch (e) {
      await Alert.show('error', e.message)
    }
  }

  const route = useRoute()

  const filterOptions = computed(() => {
    if (route.name.includes('archive')) {
      return {
        companies: companies.archive,
        organizers: organizers.archive
      }
    }

    if (route.name.includes('delete')) {
      return {
        companies: companies.deleted,
        organizers: organizers.deleted
      }
    }

    if (route.name.includes('new')) {
      return {
        companies: companies.new,
        organizers: organizers.new
      }
    }

    return {
      companies: companies.active,
      organizers: organizers.active
    }
  })

  function fillFilter(filterArr) {
    for (const key in filterFormData) {
      if (!filterFormData[key]) {
        continue
      }

      if (key === 'ResponsibleFullName') {
        const item = filterOptions.value.organizers.find(item => item.id === filterFormData[key])
        filterArr.push({ type: key, value: item?.name })
        continue
      }

      filterArr.push({ type: key, value: filterFormData[key] })
    }
  }

  return {
    filterFormData,
    filterOptions,
    fillFilter,
    resetFilter
  }
}

export function useShowcaseTradeFilterUsers({ isNew = false }) {
  const { filterFormData, resetFilter } = useFilterFormData()

  const companies = reactive([])
  const organizers = reactive([])

  onMounted(fetchOptions)

  async function fetchOptions() {
    try {
      const response = await userFilterApi.getTradeOfferCompaniesAndOrganizers({
        'search[Active]': isNew ? 'new' : 'active'
      })

      refreshArr(companies, response?.companies.map(mapCompanies))
      refreshArr(organizers, response?.responsible.map(mapOrganizers))
    } catch (e) {
      await Alert.show('error', e.message)
    }
  }

  function fillFilter(filterArr) {
    for (const key in filterFormData) {
      if (!filterFormData[key]) {
        continue
      }

      if (key === 'ResponsibleFullName') {
        const item = organizers.find(item => item.id === filterFormData[key])
        filterArr.push({ type: key, value: item?.name })
        continue
      }

      filterArr.push({ type: key, value: filterFormData[key] })
    }
  }

  return {
    filterFormData,
    companies,
    organizers,
    fillFilter,
    resetFilter
  }
}

function useFilterFormData() {
  const filterFormData = reactive({
    CompanyId: null,
    ResponsibleFullName: null,
  })

  function resetFilter() {
    filterFormData.CompanyId = null
    filterFormData.ResponsibleFullName = null
  }

  return {
    filterFormData,
    resetFilter
  }
}

function mapOrganizers(item, idx) {
  return {
    name: item,
    id: idx + 1
  }
}
function mapCompanies(user) {
  return {
    id: user.id,
    name: user.organization_name || user.name
  }
}
