<template>
  <div
    class="filter__popup"
  >
    <form class="form filter__form" @submit.prevent="$emit('close', 'filter')">
      <slot></slot>
      <div class="filter__form-buttons">
        <button
          class="btn btn-primary btn-bg-green"
          type="submit"
        >
          Найти
        </button>
        <button
          class="btn btn-link"
          type="button"
          @click="$emit('close', 'reset')"
        >
          Сбросить
        </button>
      </div>
    </form>
  </div>
</template>

<script>

import { useCloseOutsideModal } from '@/use/Base/useCloseOutsideModal'

export default {
  emits: ['close'],
  setup (props, { emit }) {
    const handleClick = (e) => {
      if (e.target.closest('.filter')) return
      emit('close')
    }

    useCloseOutsideModal(handleClick)
  }
}
</script>

<style scoped lang="scss">
.filter__form {
  justify-content: left;
  align-items: stretch;
}

.filter__form-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button:first-child {
    margin-bottom: 20px;
  }
}
</style>
