import { ref, watch } from 'vue'
import { useStore } from 'vuex'

export function useFilter (filter = null, reset = null) {
  const input = ref('')
  const store = useStore()

  watch(input, () => {
    store.commit('filter/setSearch', [{ type: 'All', value: input.value }])
  })

  const onSubmit = () => {
    store.commit('filter/setFilter', filter())
  }

  const onReset = () => {
    reset && reset()
    store.commit('filter/clearFilter')
  }

  return {
    input,
    onSubmit,
    onReset
  }
}
