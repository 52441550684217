<template>
  <DatePicker
    class="calendar"
    color="gray"
    ref="calendar"
    v-model="date"
    :is-range="isRange"
    timezone="Asia/Ashgabat"
    :mode="mode"
    :is24hr="is24hr"
    locale="ru"
    :min-date="getMinDate"
    :max-date="MAX_DATEPICKER"
    :masks="calendarMasks"
    :popover="popoverProps"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <div v-if="isRange" class="calendar__period">
        <label class="calendar__label">
          <input
            id="datePicker-start"
            class="input calendar__input"
            :placeholder="placeholder"
            :value="inputValue.start"
            v-on="inputEvents.start"
          />
        </label>

        <span class="calendar__hr" />

        <label class="calendar__label">
          <input
            id="datePicker-end"
            class="input calendar__input"
            :placeholder="placeholder"
            :value="inputValue.end"
            v-on="inputEvents.end"
          />
        </label>
      </div>

      <label v-else class="calendar__label calendar__label--single">
<!--        <VIMaskInput-->
<!--          id="datePicker"-->
<!--          mask-custom="00.00.0000"-->
<!--          :mask-type="false"-->
<!--          :value="inputValue"-->
<!--          :placeholder="placeholder"-->
<!--          v-on="inputEvents"-->
<!--        />-->
        <input
          class="input calendar__input"
          @input="inputDateRealtime"
          maxlength="10"
          id="datePicker"
          :value="inputValue"
          v-on="inputEvents"
        />
        <label
          :class="['label--placeholder label--placeholder-date', IsLengthInput ? 'active' : '']"
          for="datePicker"
        >
          {{ placeholder }}
        </label>
      </label>
    </template>

    <!-- кнопки навигации -->
    <template v-slot:header-left-button>
      <VIcon width="15" height="14" icon-name="CalendarArrow" />
    </template>
    <template v-slot:header-right-button>
      <VIcon width="15" height="14" icon-name="CalendarArrow" />
    </template>
    <template v-slot:nav-left-button>
      <VIcon width="15" height="14" icon-name="CalendarArrow" />
    </template>
    <template v-slot:nav-right-button>
      <VIcon width="15" height="14" icon-name="CalendarArrow" />
    </template>

    <template v-if="withBtns" v-slot:footer>
      <div class="calendar__btns">
        <VButton
          class="btn-bg-green"
          mod=""
          @click.prevent="setDate"
        >
          Принять
        </VButton>

        <VButton
          mod=""
          @click.prevent="onCancel"
        >
          Отмена
        </VButton>
      </div>
    </template>
  </DatePicker>
</template>

<script>
import VButton from '@/components/ui/buttons/VButton'
import VIcon from '@/components/ui/icon/VIcon'
import { DatePicker } from 'v-calendar'
import { computed, ref, watch } from 'vue'
import { inputDateRealtime } from '@/utils/utils'
import { MAX_DATEPICKER } from '@/utils/consts'

export default {
  emits: ['update:modelValue', 'input', 'change', 'reset'],
  props: {
    name: {
      type: String,
      required: false
    },
    isRange: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: [Object, String, Array, null],
      required: true
    },
    withBtns: {
      type: Boolean,
      required: false,
      default: true
    },
    is24hr: {
      type: Boolean,
      required: false,
      default: false
    },
    mode: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: true
    },
    isMin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const calendar = ref()
    const popoverProps = {
      keepVisibleOnInput: true,
      visibility: 'click'
    }
    const calendarMasks = {
      weekdays: 'WW'
    }

    const date = ref(props.modelValue)

    const IsLengthInput = computed(() => {
      return date.value
    })

    const getMinDate = computed(() => {
      return props.isMin ? new Date() : false
    })

    watch(date, () => {
      emit('change', date.value)
    })

    const setDate = () => {
      emit('update:modelValue', date.value)
      emit('input', props.name, date.value)
      calendar.value.hidePopover()
    }

    const onCancel = () => {
      date.value = ''
      emit('update:modelValue', date.value)
      emit('reset')
      calendar.value.hidePopover()
    }

    return {
      getMinDate,
      IsLengthInput,
      calendar,
      popoverProps,
      setDate,
      calendarMasks,
      date,
      onCancel,
      inputDateRealtime,
      MAX_DATEPICKER
    }
  },
  components: {
    VIcon,
    DatePicker,
    VButton
  }
}
</script>
