<template>
  <div class="filter">
    <button
      id="fil-btn"
      type="button"
      class="btn"
      @click="showPopup"
    >
      <VIcon width="48" height="48" icon-name="Filter"/>
    </button>
    <VModalFilter
      v-if="popupVisible"
      :class="getClass"
      @close="showPopup"
    >
      <slot />
    </VModalFilter>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import VModalFilter from '@/components/ui/modal/filter/VModalFilter'
import VIcon from '@/components/ui/icon/VIcon'

export default {
  components: { VIcon, VModalFilter },
  emits: [
    'on-filter',
    'on-reset'
  ],
  setup (props, { emit }) {
    const popupVisible = ref(false)
    const classModalOpen = ref('')
    const showPopup = (value) => {
      const $filterBtn = document.getElementById('fil-btn')
      const $docHeight = document.querySelector('body')
      const HEIGHT = window.getComputedStyle($docHeight, null).height
      if ((parseInt(HEIGHT) - $filterBtn.getBoundingClientRect().top) < 460) {
        classModalOpen.value = 'filter__popup--bottom'
      } else {
        classModalOpen.value = 'filter__popup--top'
      }
      popupVisible.value = !popupVisible.value
      if (typeof value === 'string') {
        emit(`on-${value}`)
      }
    }

    const getClass = computed(() => {
      return classModalOpen.value
    })

    return {
      popupVisible,
      showPopup,
      getClass
    }
  }
}
</script>

<style lang="scss" scoped>
.filter {
  margin-left: 15px;

  position: relative;

  &__popup {
    padding: 60px 38px;

    position: absolute;
    right: -470px;
    z-index: 10;

    width: 445px;
    background: #FFED00;
    filter: drop-shadow(0px 20px 40px rgba(35, 63, 31, 0.16));

    &::before {
      content: '';
      position: absolute;
      left: -20px;
      width: 30px;
      height: 22px;
      background: url("~@/assets/icon/triangle.svg") no-repeat;
      transform: rotate(-90deg);
    }

    &--top {
      top: -18px;

      &::before {
        top: 30px;
      }
    }

    &--bottom {
      top: -520px;

      &::before {
        top: 530px;
      }
    }

  }

  &__form {
    gap: 30px;
    min-width: 308px;

    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px
    }

    .form__control {
      margin-bottom: 0;
      min-height: initial;
    }
  }
}
</style>
