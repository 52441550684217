<template>
  <button :class="['btn btn-primary btn-bg-transparent btn-modified', mod === 'plus' ? 'btn-modified-plus' : 'btn-modified-next' ]">
    <VIcon v-if="mod === 'plus'" width="18" height="18" icon-name="Plus" />
    <slot />
    <VIcon v-if="mod === 'next'" width="8" height="14" icon-name="ArrowNext" />
  </button>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
export default {
  components: { VIcon },
  props: {
    mod: {
      type: String,
      required: false,
      default: 'next'
    }
  }
}
</script>
